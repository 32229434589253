import React from "react"
import * as styles from "../sections/copy.module.css";
import { Button } from "react-bootstrap"
import Container from "../components/container";
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Heading from "../components/heading"
import { FaWhatsapp } from "react-icons/fa"
import Testimonials from "../components/Testimonials"

const TiraDuvidasPage = () => (
  <Layout>
    <SEO title="Tira-Dúvidas" />
    <Container>
      <br />
      <Heading center className="cl-identity">Tira-Dúvidas</Heading>
      <Heading secondary center>
        Um bate-papo mais aberto para você tirar todas as suas dúvidas a respeito de como começar a praticar a Educação
        Domiciliar.
      </Heading>

      <Container className={styles.copyContainer}>
        <p className={styles.text}>
          O plano de consultoria <i>"Tira Dúvidas"</i> é projetado para ajudar pais ou responsáveis interessados em
          iniciar o homeschooling a esclarecer suas dúvidas iniciais. Ele oferece um encontro via chamada de vídeo
          de <b>60 minutos</b>, durante o qual os participantes terão a oportunidade de discutir e obter respostas para
          suas perguntas sobre homeschooling.
          <br /><br />
          <b>Objetivo:</b> O objetivo principal é fornecer um espaço para pais ou responsáveis interessados em
          homeschooling esclarecerem suas dúvidas iniciais e receberem orientações básicas personalizadas.
          <br /><br />
          <b>Encontro via chamada de vídeo:</b> O encontro será agendado previamente e ocorrerá através de uma
          plataforma de chamada de vídeo conveniente para todas as partes envolvidas.
          <br /><br />
          <b>Duração:</b> O encontro terá uma duração de 60 minutos, durante os quais os participantes poderão fazer
          perguntas e receber informações relevantes.
          <br /><br />
          <b>Tópicos abordados:</b> O plano de consultoria <i>"Tira Dúvidas"</i> cobrirá uma ampla gama de tópicos
          relacionados ao homeschooling. Alguns exemplos podem incluir:
          <br /><br />
          <ul style={{ marginLeft: "50px" }}>
            <li>
              <b>Aspectos legais básicos:</b> Embora não seja fornecida assessoria jurídica, serão fornecidas
              informações gerais sobre a situação jurídica do homeschooling.
            </li>
            <li>
              <b>Recursos educacionais:</b> Serão compartilhados recursos educacionais úteis, como materiais didáticos,
              livros e plataformas online.
            </li>
            <li>
              <b>Organização e estrutura:</b> Serão discutidas estratégias para estabelecer uma rotina eficiente de
              estudos em casa, criar um ambiente propício à aprendizagem e desenvolver habilidades de autodisciplina.
            </li>
            <li>
              <b>Interação social:</b> Serão exploradas maneiras de promover a interação social das crianças
              homeschoolers, como participação em atividades extracurriculares, grupos de estudo ou eventos
              comunitários.
            </li>
          </ul>
          <br />
          <b>Limitações:</b> É importante destacar que o plano de consultoria <i>"Tira Dúvidas"</i> não inclui
          assessoria jurídica específica sobre o contexto legal individual de cada participante, nem oferece
          planejamento curricular personalizado ou planos de aula detalhados.
          <br /><br />
          Esse plano de consultoria <i>"Tira Dúvidas"</i> busca fornecer suporte inicial e orientações básicas para pais
          ou responsáveis interessados em iniciar o homeschooling, ajudando-os a se sentir mais preparados para embarcar
          nessa jornada educacional em casa.
        </p>

        <div>
          <b>Investimento</b>
          <br />
          <h2 className="cl-identity font-weight-bold">R$ 78,90 no pix</h2>
          <br />
          <b>Ou em até 12x de</b>
          <p className="cl-identity">R$ 8,00 no cartão</p>
        </div>
      </Container>

      <div className={styles.buttonContainer}>
        <Button
          className={styles.learnMoreButton}
          href="https://api.whatsapp.com/send?phone=5548996507547&text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20um%20hor%C3%A1rio%20para%20tirar%20d%C3%BAvidas%20sobre%20a%20Educa%C3%A7%C3%A3o%20Domiciliar."
          target="_blank"
          rel="noopener noreferrer"
        >
          Agendar um horário
          <FaWhatsapp size={24} className="mx-2" />
        </Button>
      </div>

      <br />
      <br />
      <br />
      <Testimonials />

    </Container>
  </Layout>
)

export default TiraDuvidasPage
